// useGoogleAuth.js
import {useCallback, useState} from "react";
import {useGoogleLogin} from "@react-oauth/google";
import handleConnectGoogle from "./handleConnectGoogle";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../authentication/authContext";
import {useSnackbar} from "../notification/SnackbarContext";

export const useGoogleAuth = () => {
    const {openSnackbar} = useSnackbar();
    const [error, setError] = useState(null);
    const {connectGoogleAccount} = useAuth();
    const navigate = useNavigate();

    const onConnectSuccess = useCallback(
        async (connectedGoogleAccount, jwtToken, google_access_token, google_refresh_token, google_email) => {
            await connectGoogleAccount(connectedGoogleAccount, jwtToken, google_access_token, google_refresh_token, google_email);
            openSnackbar('Google-kontoen er koblet til.', 'success');
        },
        [connectGoogleAccount, navigate, openSnackbar]
    );

    const onConnectError = useCallback((errorMessage) => {
        setError(errorMessage);
        openSnackbar('Det oppstod en feil under tilkobling av Google-konto.', 'error');
    }, [openSnackbar]);

    const initiateGoogleConnect = useGoogleLogin({
        flow: 'auth-code',
        scope: 'openid profile email https://www.googleapis.com/auth/analytics.readonly',
        onSuccess: (codeResponse) => {
            if (codeResponse && codeResponse.code) {
                handleConnectGoogle(codeResponse.code, onConnectSuccess, onConnectError);
            } else {
                setError("Det var et problem med å hente Google-autentiseringskoden.");
            }
        },
        onError: () => {
            setError("Det var en feil med Google-autentisering.");
        }
    });

    return {initiateGoogleConnect, error};
};
