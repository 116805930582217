import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, LinearProgress } from '@mui/material';
import modalStyle from '../customComponents/modalStyle';
import loading from '../../assets/mp4/loading.mp4';
import { color } from "../../theme";

const LoadingModal = ({ open }) => {
  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        // Prevent closing the modal by clicking outside or pressing escape
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          event.preventDefault();
        }
      }}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
    >
      <Box
        sx={{
          ...modalStyle,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '400px',
          width: '400px',
        }}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Din nettside bygges...
        </Typography>
        <video
          src={loading}
          autoPlay
          loop
          muted
          style={{ width: '250px', height: '250px', marginBottom: '20px' }}
        />
        {/* You can keep or remove the progress bar */}
        <Box sx={{ width: '100%', mt: 1, color: color.green.baseGreen100 }}>
          <LinearProgress color="inherit" />
        </Box>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
