import {useEffect, useRef, useState} from 'react';
import refreshGoogleCredentials from './refreshGoogleCredentials';
import fetchProperties from "./getProperties";

const useGoogleDataFetch = (navigate, isAuthenticated, isGoogleLoggedIn, isGoogleConnected) => {
    const [properties, setProperties] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasGoogleAnalyticsProperties, setHasGoogleAnalyticsProperties] = useState(true);
    const attemptCount = useRef(0); // Track the number of fetch attempts
    const maxAttempts = 3; // Define a maximum number of attempts to prevent infinite loops

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        const fetchData = async () => {
            if (attemptCount.current >= maxAttempts) {
                console.error("Maximum data requests attempts reached. Ga4 Property data is not available.");
                setError("Maximum data requests attempts reached. Ga4 Property data is not available.");
                setLoading(false);
                return;
            }

            try {
                setLoading(true);
                const data = await fetchProperties();
                if (data.has_google_analytics_properties === false) {
                    localStorage.setItem('hasGoogleAnalyticsProperties', "false");
                    setHasGoogleAnalyticsProperties(false);
                } else {
                    localStorage.setItem('hasGoogleAnalyticsProperties', "true");
                    setHasGoogleAnalyticsProperties(true);
                    setProperties(data);
                }
                setLoading(false);
                setError(null); // Clear any existing error
                attemptCount.current = 0; // Reset attempt count on successful fetch
            } catch (error) {
                attemptCount.current++; // Increment attempt count on failure

                if (error.response && error.response.status === 401) {
                    try {
                        const refreshToken = localStorage.getItem('google_refresh_token');
                        await refreshGoogleCredentials(refreshToken);
                        // Retry the fetch after refreshing the token
                    } catch (refreshError) {
                        console.error("Error refreshing Google credentials:", refreshError);
                        navigate('/login');
                    }
                } else {
                    setError(error); // Set the error state
                    setProperties(null); // Explicitly set properties to null on error
                    setLoading(false);
                    console.error("Error fetching properties:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        if (localStorage.getItem('connectedGoogleAccount') === 'true' ||
            localStorage.getItem('createdViaGoogle') === 'true') {
            fetchData();
        }
        ;
    }, [navigate, isAuthenticated, isGoogleLoggedIn, isGoogleConnected]);

    return {properties, loading, error, hasGoogleAnalyticsProperties};
};

export default useGoogleDataFetch;
