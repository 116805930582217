import React from 'react';
import {Box, Skeleton} from '@mui/material';

const SkeletonTrafficChart = () => {
    return (
        <Box sx={{width: '100%', height: '400px', display: 'flex', flexDirection: 'column', padding: '20px'}}>
            <Box sx={{display: 'flex', gap: '8px', marginBottom: '24px', alignItems: 'center'}}>
                <Skeleton variant="text"
                          sx={{fontSize: '2rem', width: '75px', marginRight: '2em'}}/>
                <Skeleton variant="rectangular" sx={{width: '50px', height: '24px', borderRadius: '4px'}}/>
                <Skeleton variant="rectangular" sx={{width: '50px', height: '24px', borderRadius: '4px'}}/>
                <Skeleton variant="rectangular" sx={{width: '50px', height: '24px', borderRadius: '4px'}}/>
                <Skeleton variant="rectangular" sx={{width: '50px', height: '24px', borderRadius: '4px'}}/>
                <Skeleton variant="rectangular" sx={{width: '50px', height: '24px', borderRadius: '4px'}}/>
                <Skeleton variant="rectangular" sx={{width: '50px', height: '24px', borderRadius: '4px'}}/>
            </Box>
            <Skeleton variant="rectangular" sx={{width: '95%', height: '300px', borderRadius: '8px'}}/>
        </Box>
    );
};

export default SkeletonTrafficChart;
