import {styled} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import {color, typography} from "../../theme"

const CustomInputLabel = styled(InputLabel)({
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontSize: typography.body.fontSize,
    color: color.black.black100,
});

export {CustomInputLabel}
