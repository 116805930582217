import React, {useEffect} from 'react';
import {getSubscriptionStatus} from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPageLayout from '../../components/stripe/SuccessPageLayout';
import {useNavigate} from "react-router-dom";

const PaymentSuccessNettsidePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    const description = (
        <ul style={{textAlign: 'left', lineHeight: '2'}}>
            <li>Du kan nå <a href="/nettside" style={{textDecoration: 'none', color: '#6A1B9A'}}> bygge din egen
                nettside</a> og publisere dem live.
            </li>
            <li>Hvis du oppgraderer til <a href="/settings" style={{
                textDecoration: 'none',
                color: '#6A1B9A'
            }}>Nettside + Guider</a>, får du tilgang til eksklusive <a href="/guider" style={{
                textDecoration: 'none',
                color: '#6A1B9A'
            }}> Guider</a> hvor du kan lære mer om
                digital markedsføring og hvordan du kan få mer trafikk til nettsiden din.
            </li>
        </ul>
    );

    return (
        <SuccessPageLayout
            title="Betaling mottatt for Nettsideplan"
            subscriptionTypeText="Betalingen din var vellykket. Du har nå abonnert med Nettsideplan."
            description={description}
            buttonText="Gå tilbake til nettside"
            buttonAction={() => navigate('/nettside')}
        />
    );
};

export default PaymentSuccessNettsidePage;
