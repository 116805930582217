import React from 'react';
import {Box, Card, CardContent, Container, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Navbar from '../../components/navbar/Navbar';
import {color} from '../../theme';
import {CustomButton} from '../customComponents/CustomButton';


const SuccessPageLayout = ({ title, subscriptionTypeText, description, buttonText, buttonAction }) => {

    return (
        <Container sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }} maxWidth="lg">
            <Navbar />
            <Box sx={{
                marginLeft: '208px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <Card sx={{ minWidth: 275, padding: '2em' }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                        <CheckCircleIcon
                            sx={{
                                fontSize: 100,
                                color: color.green.baseGreen100,
                                animation: 'pop 1.2s ease-in-out',
                                '@keyframes pop': {
                                    '0%': { transform: 'scale(0)' },
                                    '70%': { transform: 'scale(1.2)' },
                                    '100%': { transform: 'scale(1)' }
                                }
                            }}
                        />
                        <Typography variant="h4" component="div" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body" sx={{ marginTop: '0.5em' }}>
                            {subscriptionTypeText}
                        </Typography>
                        <Typography variant="body" display="block" sx={{ marginTop: '0.5em',width: '76%', margin: 'auto'}}>
                            {description}
                        </Typography>
                        <Box sx={{ marginTop: '2em' }}>
                            <CustomButton variant="filled" onClick={buttonAction}>{buttonText}</CustomButton>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    );
};

export default SuccessPageLayout;
