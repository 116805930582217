import {fetchSubscriptionStatus} from "./fetchSubscriptionStatus";

export const getSubscriptionStatus = async () => {
    const subscriptionData = await fetchSubscriptionStatus();
    let subscriptionType = subscriptionData.subscription_type;
    localStorage.setItem('subscription_status', subscriptionData.status);
    localStorage.setItem('subscription_type', subscriptionType);
    let accountType = 'Gratis-konto';
    if (subscriptionData.status === 'trialing') {
        accountType = 'Trial-konto';
    } else if (subscriptionData.status === 'active') {
        accountType = 'Abonnert';
    }
    localStorage.setItem('account_type', accountType);
}