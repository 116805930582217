import React, {useEffect} from 'react';
import {getSubscriptionStatus} from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPageLayout from '../../components/stripe/SuccessPageLayout';
import {useNavigate} from "react-router-dom";

const PaymentSuccessPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    const subscriptionTypeText = 'Betalingen din var vellykket, og du er nå abonnert med Nettside + Guider.';

    const description = (
        <ul style={{textAlign: 'left', lineHeight: '2'}}>
            <li>Du kan nå <a href="/nettside" style={{textDecoration: 'none', color: '#6A1B9A'}}> bygge din egen
                nettside</a> og publisere dem live.
            </li>
            <li>Du kan se alle videokursene i
                <a href="/guider" style={{textDecoration: 'none', color: '#6A1B9A'}}> Guider</a> for å lære mer om
                digital markedsføring og hvordan du kan få mer trafikk til nettsiden din.
            </li>
        </ul>
    );

    return (
        <SuccessPageLayout
            title="Betaling mottatt"
            subscriptionTypeText={subscriptionTypeText}
            description={description}
            buttonText="Gå tilbake til dashboard"
            buttonAction={() => navigate('/')}
        />
    );
};

export default PaymentSuccessPage;
