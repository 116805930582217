import React, { useEffect } from 'react';
import { getSubscriptionStatus } from '../../helperFunctions/stripe/getSubscriptionStatus';
import SuccessPageLayout from  '../../components/stripe/SuccessPageLayout';
import {useNavigate} from "react-router-dom";

const TrialSuccessPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        getSubscriptionStatus();
    }, []);

    const description = (
        <ul style={{textAlign: 'left', lineHeight: '2'}}>
            <li>Du har opp til 30 dager gratis prøveperiode og vil ikke bli belastet før prøveperioden er over.</li>
            <li>Du kan oppdatere betalingsinformasjon og se hvor lenge du har igjen av prøveperioden i
                <a href="/settings" style={{textDecoration: 'none', color: '#6A1B9A'}}> Innstillinger</a>.
            </li>
            <li>Du kan nå <a href="/nettside" style={{textDecoration: 'none', color: '#6A1B9A'}}> bygge din egen
                nettside</a> og publisere dem live.
            </li>
            <li>Du kan se alle videokursene i
                <a href="/guider" style={{textDecoration: 'none', color: '#6A1B9A'}}> Guider</a> for å lære mer om
                digital markedsføring og hvordan du kan få mer trafikk til nettsiden din.
            </li>
        </ul>
    );

    return (
        <SuccessPageLayout
            title="Trial startet"
            subscriptionTypeText="Din trial-registrering er vellykket, og du er nå abonnert."
            description={description}
            buttonText="Gå tilbake til dashboard"
            buttonAction={() => navigate('/')}
        />
    );
};

export default TrialSuccessPage;
