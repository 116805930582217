// Footer.js
import React from 'react';
import {Box, Typography} from '@mui/material';

const PersonvernFooter = ({position = "relative", bottom = "-30px", left = "55%"}) => {
    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                px: 4,
                mt: 'auto',
                backgroundColor: 'transparent',
                position: 'relative', // Assuming you want it relative to its nearest positioned ancestor
                bottom: bottom,
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            }}
        >
            <Typography variant="caption" align="center" sx={{fontFamily: 'inherit'}}>
                © {new Date().getFullYear()} Groweb AS, alle rettigheter reservert.
                {' '}
                <a href="/personvern" target="_blank"
                   rel="noopener noreferrer">
                    Personvernsvilkår
                </a> & <a href="/vilkår" target="_blank" rel="noopener noreferrer">Bruksvilkår</a>
            </Typography>
        </Box>

    );
};

export default PersonvernFooter;
