import axiosInstance from "../authentication/axiosInstance";

const fetchProperties = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
        const response = await axiosInstance.get('/getproperties/', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'csrfToken': 'csrftoken',
            }
        });
        return response.data;
    } catch (err) {
        console.error("Error:", err.response); // Print the error response here
        if (err.response && err.response.status === 401) {
            throw new Error("Unauthenticated");
        } else {
            console.error("Error fetching properties:", err);
            // window.location.href = '/login';
        }
    }
}

export default fetchProperties;
