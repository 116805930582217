import axiosInstance from "../../helperFunctions/authentication/axiosInstance";

const handleLogin = async (params, navigate, login, setError) => {
    try {
        const backendResponse = await axiosInstance.post('/login/', params);

        if (backendResponse.status === 200) {
            const jwtToken = backendResponse.data.token;
            const jwtRefreshToken = backendResponse.data.refresh_token;
            const createdViaGoogle = backendResponse.data.created_via_google;
            const connectedGoogleAccount = backendResponse.data.connected_google_account;
            const googleAccessToken = backendResponse.data.google_access_token;
            const googleRefreshToken = backendResponse.data.google_refresh_token;
            const googleEmail = backendResponse.data.google_email;
            const email = backendResponse.data.email;
            const isAdmin = backendResponse.data.is_admin;
            const brizy_user_id = backendResponse.data.brizy_user_id;
            const workspace_id = backendResponse.data.workspace_id;

            // Update auth state
            await login(jwtToken, createdViaGoogle, connectedGoogleAccount, jwtRefreshToken, backendResponse.data.first_name,
                backendResponse.data.last_name, googleAccessToken, googleRefreshToken, googleEmail, email, isAdmin, brizy_user_id,
                workspace_id,);
            navigate("/");
        } else {
            setError("Innlogging mislyktes. Prøv igjen senere. (Code: " + backendResponse.status + ")");
        }
    } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
            setError(err.response.data.error);
        } else {
            setError("Det var et problem med serverkommunikasjonen.");
        }
    }
};

export default handleLogin;
