import React, {useEffect, useState} from 'react';
import {Alert, Autocomplete, Box, CircularProgress, IconButton, Modal, Paper, Typography} from '@mui/material';
import {CustomButton} from '../customComponents/CustomButton';
import axiosInstance from '../../helperFunctions/authentication/axiosInstance';
import {CustomTextField} from "../customComponents/CustomTextField";
import CloseIcon from "@mui/icons-material/Close";
import CustomTooltip from "../customComponents/CustomTooltip";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import industryTranslations from "../../helperFunctions/nettside/translations/industryTranslations";
import {styled} from "@mui/system";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ReactCountryFlag from "react-country-flag";
import {ArrowForwardOutlined} from "@mui/icons-material";
import modalStyle from "../customComponents/modalStyle";

const PoppinsPaper = styled(Paper)(({theme}) => ({
    fontFamily: 'Poppins, sans-serif'
}));

const PoppinsListbox = styled('ul')({
    fontFamily: 'Poppins, sans-serif',
    padding: 0,
    margin: 0,
    listStyle: 'none'
});

const languages = [
    {code: 'NO', name: 'Norsk'},
    {code: 'GB', name: 'Engelsk'},
];

const AiBuilderModal = ({
                            open = false,
                            handleClose = () => {
                            },
                            setSsoLink,
                            aiFormData,
                            setAiFormData,
                            setErrorMessage,
                            handleSubmit,
                        }) => {
    const [step, setStep] = useState(1);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!open) {
            setStep(1);
            setError('');
        }
    }, [open]);

    const handleGetSuggestions = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.post('/get-company-names/', {prompt: aiFormData.businessName});
            setSuggestions(response.data.slice(0, 6)); // Only show the first 6 suggestions
            setError('');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Failed to fetch suggestions. Please try again.');
            }
        }
        setLoading(false);
    };

    const handleGetIndustrySuggestions = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.post('/get-industry-names/', {prompt: aiFormData.industry});
            setSuggestions(response.data.slice(0, 8)); // Only show the first 6 suggestions
            setError('');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Failed to fetch suggestions. Please try again.');
            }
        }
        setLoading(false);
    };

    const handleSelectSuggestion = (suggestion) => {
        if (step === 1) {
            setAiFormData((prevData) => ({...prevData, businessName: suggestion}));
        } else {
            setAiFormData((prevData) => ({...prevData, industry: suggestion}));
        }
    };

    const handleProceed = () => {
        setStep(step + 1);
        setSuggestions([]);
    };

    const handlePrevious = () => {
        setStep(step - 1);
        setSuggestions([]);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setAiFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFinalSubmit = async (e) => {
        setIsSubmitting(true);
        try {
            await handleSubmit(e);
            setError('');
        } catch (submitError) {
            if (submitError.response && submitError.response.data && submitError.response.data.error) {
                setError(submitError.response.data.error);
            } else {
                setError('Failed to submit. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{...modalStyle, width: 600}}>
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                    }}
                >
                    <CloseIcon fontSize="large"/>
                </IconButton>
                <Typography variant="h4" component="h4" sx={{textAlign: 'center', mt: '2em', mb: '1em'}}>
                    Bygg din nettside med AI
                </Typography>
                {step === 1 && (
                    <>
                        <CustomTooltip
                            title="Navnet til bedriften din. Dette vil bli brukt til å generere logoer og mer."
                            arrow>
                            <CustomTextField
                                fullWidth
                                label="Bedriftsnavn"
                                value={aiFormData.businessName}
                                name="businessName"
                                onChange={handleChange}
                                sx={{mb: 2}}
                            />
                        </CustomTooltip>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline'}}>
                            <Box>
                                <CustomTooltip
                                    title="(BETA) Tom for ideer? La kunstig intelligens generere navn forslag for deg!"
                                    arrow>
                                    <CustomButton colors="quaternary" variant="contained" size="small"
                                                  sx={{textTransform: 'none'}}
                                                  onClick={handleGetSuggestions}
                                                  disabled={loading}
                                                  endIcon={!loading && <AutoAwesomeOutlinedIcon/>}>
                                        {loading ? <CircularProgress size={24}/> : 'Generer forslag'}
                                    </CustomButton>
                                </CustomTooltip>
                            </Box>
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <CustomTooltip
                            title="Velg industri-bransjen din. Dette vil hjelpe oss med å tilpasse nettstedet ditt."
                            arrow>
                            <Autocomplete
                                fullWidth
                                options={Object.keys(industryTranslations)}
                                getOptionLabel={(option) => industryTranslations[option] || option}
                                value={aiFormData.industry}
                                onChange={(event, newValue) => {
                                    setAiFormData((prevData) => ({...prevData, industry: newValue}));
                                }}
                                renderInput={(params) => (
                                    <CustomTextField
                                        {...params}
                                        label="Bransje"
                                        variant="outlined"
                                        sx={{mb: 2}}
                                    />
                                )}
                                PaperComponent={PoppinsPaper}
                                ListboxComponent={PoppinsListbox}
                                freeSolo
                            />
                        </CustomTooltip>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline'}}>
                            <Box>
                                <CustomTooltip
                                    title="(BETA) Tom for ideer? La kunstig intelligens generere bransje forslag for deg!"
                                    arrow>
                                    <CustomButton colors="quaternary" variant="contained" size="small"
                                                  sx={{textTransform: 'none'}}
                                                  onClick={handleGetIndustrySuggestions}
                                                  disabled={loading}
                                                  endIcon={!loading && <AutoAwesomeOutlinedIcon/>}>
                                        {loading ? <CircularProgress size={24}/> : 'Generer forslag'}
                                    </CustomButton>
                                </CustomTooltip>
                            </Box>
                        </Box>
                    </>
                )}
                {step === 3 && (
                    <>
                        <CustomTooltip
                            title="En kort beskrivelse av bedriften din. Dette vil hjelpe AI til å generere bedre innhold."
                            arrow>
                            <CustomTextField
                                fullWidth
                                label="Bedriftsbeskrivelse"
                                value={aiFormData.description}
                                name="description"
                                onChange={handleChange}
                                multiline
                                minRows={4}
                                maxRows={4}
                                helperText="Valgfritt"
                                sx={{mb: 2}}
                            />
                        </CustomTooltip>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                            <CustomTooltip title="Telefonnummeret til bedriften din." arrow>
                                <CustomTextField
                                    fullWidth
                                    label="Telefonnummer"
                                    value={aiFormData.phone}
                                    name="phone"
                                    onChange={handleChange}
                                    helperText="Valgfritt"
                                    sx={{mr: 1}}
                                />
                            </CustomTooltip>
                            <CustomTooltip title="E-postadressen til bedriften din." arrow>
                                <CustomTextField
                                    fullWidth
                                    label="E-postadresse"
                                    value={aiFormData.email}
                                    name="email"
                                    onChange={handleChange}
                                    helperText="Valgfritt"
                                    sx={{ml: 1}}
                                />
                            </CustomTooltip>
                        </Box>
                        <CustomTooltip
                            title="Byen hvor bedriften din er lokalisert."
                            arrow>
                            <CustomTextField
                                fullWidth
                                label="By"
                                value={aiFormData.city}
                                name="city"
                                onChange={handleChange}
                                helperText="Valgfritt"
                                sx={{mb: 2}}
                            />
                        </CustomTooltip>
                        <CustomTooltip title="Velg språk av KI-generert tekst for din nettside." arrow>
                            <Autocomplete
                                options={languages}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props}>
                                        <ReactCountryFlag
                                            countryCode={option.code}
                                            svg
                                            style={{
                                                width: '2em',
                                                height: '2em',
                                                marginRight: '8px'
                                            }}
                                            title={option.code}
                                        />
                                        {option.name}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <CustomTextField
                                        {...params}
                                        label="Språk"
                                        variant="outlined"
                                        size="medium"
                                        margin="normal"
                                        fullWidth
                                    />
                                )}
                                onChange={(event, newValue) => setAiFormData((prevData) => ({
                                    ...prevData,
                                    language: newValue ? newValue.code : ''
                                }))}
                                value={languages.find(c => c.code === aiFormData.language) || null}
                                PaperComponent={PoppinsPaper}
                                ListboxComponent={PoppinsListbox}
                                sx={{width: '100%'}}
                            />
                        </CustomTooltip>
                    </>
                )}
                <Box sx={{mt: 2, display: 'grid'}}>
                    {error &&
                        <Alert severity="error" sx={{mt: 2, margin: 'auto', fontFamily: 'inherit'}}>{error}</Alert>}
                    {suggestions.length > 0 && (
                        <>
                            <Typography variant="body" sx={{mb: '1em'}}>Forslag fra KI</Typography>
                            <Box>
                                {suggestions.map((suggestion, index) => (
                                    <CustomButton variant="outlined" rounded size="small" key={index}
                                                  onClick={() => handleSelectSuggestion(suggestion)}
                                                  sx={{mr: '0.5em', mb: '0.5em', textTransform: 'capitalize'}}
                                    >
                                        {suggestion}
                                    </CustomButton>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                <Box sx={{
                    textAlign: 'center',
                    mt: 3,
                    display: 'flex',
                    justifyContent: step === 1 ? 'center' : 'space-between'
                }}>
                    {step > 1 && (
                        <CustomButton onClick={handlePrevious} colors="sixth" sx={{mr: 2}}
                                      startIcon={<ArrowBackOutlinedIcon/>}
                        >
                            Forrige steg
                        </CustomButton>
                    )}
                    <CustomButton
                        onClick={step === 3 ? handleFinalSubmit : handleProceed}
                        colors="sixth"
                        disabled={(step === 1 && !aiFormData.businessName) || (step === 2 && !aiFormData.industry)}
                        endIcon={isSubmitting ? <CircularProgress size={20} color="inherit"/> : (step === 3 ?
                            <AutoAwesomeOutlinedIcon/> : <ArrowForwardOutlined/>)}>
                        {step === 3 ? 'Fullfør' : 'Fortsett'}
                    </CustomButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default AiBuilderModal;
