import React, {lazy, memo, Suspense, useCallback, useEffect, useMemo, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Alert, Box, CircularProgress, Container, Snackbar, styled} from "@mui/material";
import Navbar from "../components/navbar/Navbar";
import useGoogleDataFetch from "../helperFunctions/google/useGoogleDataFetch";
import {useAuth} from "../helperFunctions/authentication/authContext";
import {useNavigate} from "react-router-dom";
import DashboardDropdowns from "../components/dashboard/dropdowns/DashboardDropdowns";
import '../assets/css/dashboard/dashboard.css';
import useMetrics from "../helperFunctions/google/useMetrics";
import {fetchDataForTimePeriod} from "../helperFunctions/dashboard/fetchDataForTimePeriod";
import {TrafficChartNoProperties} from "../components/charts/TrafficChartNoProperties";
import GoogleConnectButton from "../components/buttons/GoogleConnectButton";
import quickStatsUtenProperties from "../assets/img/quickStatsUtenProperties.webp";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {useSnackbar} from "../helperFunctions/notification/SnackbarContext";
import Button from "@mui/material/Button";
import PersonvernFooter from "../components/personvern/PersonvernFooter";
import Typography from '@mui/material/Typography';
import AssessmentSharpIcon from '@mui/icons-material/AssessmentSharp';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TimerIcon from '@mui/icons-material/Timer';
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import {CustomCard} from '../components/customComponents/CustomCard';
import {color} from "../theme"
import SkeletonStatsCard from "../components/skeleton/SkeletonStatsCard";
import SkeletonTrafficChart from "../components/skeleton/SkeletonTrafficChart";
import {fetchSubscriptionStatus} from "../helperFunctions/stripe/fetchSubscriptionStatus";
import {BliKjent} from "../components/dashboard/bliKjent/BliKjent";
import LagNettside from "../components/dashboard/lagNettside/LagNettside";

// Lazy loading
const TrafficChart = lazy(() => import('../components/charts/TrafficChart'));
// const TasksTable = lazy(() => import('../components/dashboard/tasksTable/TasksTable'));
const NewsFeed = lazy(() => import('../components/dashboard/news/NewsFeed'));
const StatsCard = lazy(() => import('../components/dashboard/StatsCard'));
const LoadingIndicator = memo(function LoadingIndicator({size}) {
    return <CircularProgress size={size}/>;
});
// const RecommendGuides = lazy(() => import('../components/dashboard/recommendGuides/RecommendGuides'));

const AlertOverlay = styled('div')(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '400px',
    margin: '0 auto',
    backgroundColor: color.white.white10,
}));

export default function Dashboard() {
    const navigate = useNavigate();
    const {isAuthenticated, isGoogleLoggedIn, isGoogleConnected} = useAuth();
    const {
        properties,
        error,
        hasGoogleAnalyticsProperties
    } = useGoogleDataFetch(navigate, isAuthenticated, isGoogleLoggedIn,
        isGoogleConnected);

    const {
        selectedData,
        metricsLoading,
        setMetricsLoading,
        selectedPropertyId,
        timePeriod,
        setSelectedPropertyId,
        updateTimePeriod
    } = useMetrics("", "last_week");
    // time periods: today, yesterday, last_week, last_month, last_3_months, last_6_months, last_year, all_time

    // State to hold data for the dashboard quick stats
    const [quickStats, setQuickStats] = useState(null);

    // New state for the local time period for TrafficChart only
    const [chartTimePeriod, setChartTimePeriod] = useState(timePeriod);
    const [chartData, setChartData] = useState(null); // State to hold data for the TrafficChart
    const [localAdjustmentMade, setLocalAdjustmentMade] = useState(false);
    const [chartLoading, setChartLoading] = useState(false); // New state for tracking TrafficChart loading

    // Snackbar state and functions
    const {snackbar, closeSnackbar} = useSnackbar();

    // Memoize average bounce rate calculation
    const averageBounceRate = useMemo(() => {
        if (!quickStats?.bounce_rate) return null;
        const bounceRates = quickStats.bounce_rate.rows.map(row => parseFloat(row.metrics[0]) * 100);
        const average = bounceRates.reduce((acc, val) => acc + val, 0) / bounceRates.length;
        return average.toFixed(2); // Keeping two decimals for average
    }, [quickStats?.bounce_rate]);

    // Memoize average time on page calculation
    const averageTimeOnPage = useMemo(() => {
        if (!quickStats?.average_session_duration) return null;
        const totalTimeOnPage = quickStats.average_session_duration.rows.reduce((acc, row) => acc + parseFloat(row.metrics[0]), 0);
        return (totalTimeOnPage / quickStats.average_session_duration.rows.length).toFixed(2);
    }, [quickStats?.average_session_duration]);

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.round(totalSeconds % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')} min`; // Outputs as "X:YY min"
    };

    // Memoize total page views calculation
    const totalPageViews = useMemo(() => {
        if (!quickStats?.page_views) return null;
        return quickStats.page_views.rows.reduce((acc, row) => acc + parseInt(row.metrics[0]), 0);
    }, [quickStats?.page_views]);

    // Memoize total conversions calculation
    const totalConversions = useMemo(() => {
        if (!quickStats?.conversions) return null;
        return quickStats.conversions.rows.reduce((acc, row) => acc + parseInt(row.metrics[0]), 0);
    }, [quickStats?.conversions]);

    // Effect for handling changes in the chart time period
    const fetchChartData = useCallback(async () => {
        if (!selectedPropertyId) return;

        try {
            setChartLoading(true);
            const data = await fetchDataForTimePeriod(selectedPropertyId, chartTimePeriod);
            setChartData(data);
        } catch (error) {
            console.error("Error fetching chart data:", error);
        } finally {
            setChartLoading(false);
        }
    }, [selectedPropertyId, chartTimePeriod, fetchDataForTimePeriod]);

    const [propertyList, setPropertyList] = useState([]);

    useEffect(() => {
        // Set the property list and default property ID when properties are fetched
        if (properties && Object.keys(properties).length > 0) {
            const accounts = Object.values(properties);
            const propertyArray = accounts.reduce((acc, account) => {
                if (account && account.properties) {
                    const props = Object.values(account.properties).map(prop => ({
                        propertyNumber: prop.property_number,
                        displayName: prop.display_name,
                        propertyType: prop.property_type,
                    }));
                    return [...acc, ...props];
                }
                return acc;
            }, []);

            setPropertyList(propertyArray);


            const storedPropertyId = localStorage.getItem('selectedPropertyId');
            const isValidStoredId = propertyArray.some(prop => prop.propertyNumber === storedPropertyId);


            if (isValidStoredId) {
                setSelectedPropertyId(storedPropertyId);
            } else if (propertyArray.length > 0) {
                setSelectedPropertyId(propertyArray[0].propertyNumber);
            }
        }
    }, [properties, setSelectedPropertyId]);


    useEffect(() => {
        fetchChartData();
    }, [fetchChartData]);

    // Separate useEffect for metrics loading to handle the global time period changes.
    useEffect(() => {
        if (selectedPropertyId && timePeriod) {
            setMetricsLoading(true);
            fetchDataForTimePeriod(selectedPropertyId, timePeriod)
                .then(data => {
                    setQuickStats(data);
                })
                .catch(error => {
                    console.error("Error fetching data for quick stats", error);
                })
                .finally(() => {
                    setMetricsLoading(false);
                });
        }
    }, [selectedPropertyId, timePeriod, localAdjustmentMade]);

    // PERFORMANCE
    useEffect(() => { // Preload the quickStatsUtenProperties image
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = quickStatsUtenProperties;
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, []);

    // Fetch subscription status
    useEffect(() => {
        const getSubscriptionStatus = async () => {
            const subscriptionData = await fetchSubscriptionStatus();
            if (subscriptionData) {
                localStorage.setItem('subscription_status', subscriptionData.status);
                localStorage.setItem('subscription_type', subscriptionData.subscription_type);
            }
        };

        getSubscriptionStatus();
    }, []);

    const renderStatsCard = (data, title, tooltipTitle, icon, color, background) => {
        if (metricsLoading || !quickStats) {
            return <SkeletonStatsCard/>;
        }

        return (
            <StatsCard
                title={title}
                tooltipTitle={tooltipTitle}
                data={data}
                icon={icon}
                color={color}
                background={background}
                errorMsg="Ingen data tilgjengelig for denne nettsiden eller perioden."
            />
        );
    };

    return (
        <Container sx={{marginTop: 8}} maxWidth="100vw">
            <Navbar/>
            <Box sx={{marginLeft: '208px'}}>
                <Box sx={{width: 'fit-content', margin: 'auto'}}>
                    <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={closeSnackbar}
                              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    >
                        <Alert onClose={closeSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    <Box display='flex' flexDirection='column'>
                        <Grid item xs={12} mb={3}>
                            <div className="welcome-message">
                                <Typography variant="h3"
                                            sx={{fontFamily: 'Fraunces'}}>Hei, {localStorage.getItem('first_name')}!</Typography>
                            </div>
                        </Grid>
                        {
                            (isGoogleLoggedIn || isGoogleConnected) && (
                                <Grid item xs={12} md={6}>
                                    <DashboardDropdowns
                                        metricsData={selectedData}
                                        updateTimePeriod={updateTimePeriod}
                                        properties={properties || []}
                                        setSelectedPropertyId={setSelectedPropertyId}
                                        selectedPropertyId={selectedPropertyId}
                                        timePeriod={timePeriod}
                                    />
                                </Grid>
                            )
                        }
                        {isGoogleLoggedIn || isGoogleConnected ? (
                            hasGoogleAnalyticsProperties ? (
                                <Grid item xs={12}>
                                    <div className="quick-stats-container">
                                        <div className="quick-stats">
                                            {renderStatsCard(totalPageViews, "Besøkende", "Antall besøkere viser hvor mange unike brukere som har besøkt nettstedet i den valgte perioden.", FavoriteSharpIcon, color.darkBlue.baseBlue100, color.lightBlue.baseBlue25)}
                                            {renderStatsCard(averageBounceRate, "Fluktrate", "Fluktrate beskriver prosentandelen av besøk som forlater nettstedet etter å ha sett bare én side.", AssessmentSharpIcon, color.green.green1, color.green.baseGreen25)}
                                            {renderStatsCard(formatTime(averageTimeOnPage), "Besøkstid i snitt", "Gjennomsnittstiden besøkende tilbringer på nettsiden din.", TimerIcon, color.orange.orange1, color.orange.baseOrange25)}
                                            {renderStatsCard(totalConversions, "Konverteringer", "Konverteringer representerer antall viktige handlinger som brukere har fullført på nettstedet, som kjøp, registreringer, og nedlastinger.", BusinessCenterIcon, color.darkGrey.darkGrey100S1, color.lightGrey.lightGrey25S2)}
                                        </div>
                                    </div>
                                </Grid>
                            ) : (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Du må aktivere Google Analytics for å se din nettside data. Aktiver Google
                                        Analytics for din Google-konto
                                        her: <a href="https://analytics.google.com/" target="_blank"
                                                rel="noopener noreferrer">Gå til Google Analytics</a>.
                                    </Alert>
                                </Grid>
                            )
                        ) : (
                            <Grid item xs={12} sx={{maxWidth: '1214px'}}>
                                <div className="quick-stats-container">
                                    <div className="alert-google-connect" style={{textAlign: 'center'}}>
                                        <img src={quickStatsUtenProperties} alt="Quick Stats Unavailable"
                                             style={{filter: 'blur(5px)', width: '100%', height: 'auto'}}/>
                                        <AlertOverlay>
                                            <InfoIcon
                                                sx={{fontSize: '2.5rem', color: color.lightBlue.baseBlue100}}/>
                                            <div className="alert-text">
                                                Koble til Google Analytics-kontoen din med Groweb for å aktivere
                                                datainnsikt.
                                            </div>
                                            <GoogleConnectButton/>
                                        </AlertOverlay>
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {
                            // Render the error message and refresh button if there is an error
                            error && (
                                <Grid className="error-alert" item xs={5}>
                                    <Alert severity="error" action={
                                        <Button sx={{display: 'flex'}} color="primary" size="small"
                                                onClick={() => navigate('/logout')}>
                                            <strong>Last opp siden på nytt.</strong>
                                        </Button>
                                    } sx={{marginLeft: 0}}>
                                        <Typography variant="body">Beklager, en feil skjedde ved henting av Google
                                            data.</Typography>
                                    </Alert>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} sx={{display: 'flex', mb: '2em', mt: '1em'}}>
                            <BliKjent/>
                            <LagNettside/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex'}}>
                            <CustomCard className='traffic-chart-container' size="l">
                                {!hasGoogleAnalyticsProperties || !localStorage.getItem('hasGoogleAnalyticsProperties') ? (
                                    <TrafficChartNoProperties/>
                                ) : chartLoading ? (
                                    <SkeletonTrafficChart/>
                                ) : !chartData || !selectedData ? (
                                    <SkeletonTrafficChart/>
                                ) : (
                                    <Suspense fallback={<LoadingIndicator size={100}/>}>
                                        <TrafficChart
                                            data={chartData}
                                            globalTimePeriod={timePeriod}
                                            chartTimePeriod={chartTimePeriod}
                                            setChartTimePeriod={setChartTimePeriod}
                                            setLocalAdjustmentMade={setLocalAdjustmentMade}
                                            hasGoogleAnalyticsProperties={hasGoogleAnalyticsProperties}
                                        />
                                    </Suspense>
                                )}
                            </CustomCard>
                            <NewsFeed/>
                        </Grid>
                    </Box>
                    <PersonvernFooter/>
                </Box>
            </Box>
        </Container>
    );
}