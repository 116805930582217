import {styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import '../../colors.css'
import PropTypes from "prop-types";

import {color} from "../../theme"

const CustomButton = styled(Button)(
    ({theme, colors, rounded, variant, size}) => {
        const isOutlined = variant === "outlined";

        return {
            borderRadius: rounded ? `${size == "large" ? "20px" : "16px"}` : `${size == "large" ? "8px" : "4px"}`,
            padding: size == "large" ? "12px 24px" : "6px 12px",
            fontWeight: "600",
            "& .MuiButton-startIcon>*:nth-of-type(1), & .MuiButton-endIcon>*:nth-of-type(1)":
                {
                    fontSize: "25px",
                },
            ...(colors === "primary" && {
                backgroundColor: !isOutlined
                    ? color.lightBlue.baseBlue100
                    : "transparent",
                color: isOutlined
                    ? color.lightBlue.baseBlue100
                    : color.primary.contrastText,
                border: isOutlined ? `2px solid ${color.lightBlue.baseBlue100}` : "none",
                "&:hover": {
                    backgroundColor: !isOutlined
                        ? color.darkBlue.baseBlue100
                        : "transparent",
                    border: isOutlined
                        ? `2px solid ${color.darkBlue.baseBlue100}`
                        : "none",
                },
            }),
            ...(colors === "secondary" && {
                backgroundColor: !isOutlined
                    ? color.darkBlue.baseBlue100
                    : "transparent",
                color: isOutlined
                    ? color.darkBlue.baseBlue100
                    : color.primary.contrastText,
                border: isOutlined ? `2px solid ${color.darkBlue.baseBlue100}` : "none",
                "&:hover": {
                    backgroundColor: !isOutlined
                        ? color.lightBlue.baseBlue100
                        : "transparent",
                    border: isOutlined
                        ? `2px solid ${color.lightBlue.baseBlue100}`
                        : "none",
                },
            }),
            ...(colors === "tertiary" && {
                backgroundColor: !isOutlined
                    ? color.orange.baseOrange100
                    : "transparent",
                color: isOutlined
                    ? color.orange.baseOrange100
                    : color.primary.contrastText,
                border: isOutlined ? `2px solid ${color.orange.baseOrange100}` : "none",
                "&:hover": {
                    backgroundColor: !isOutlined ? color.orange.orange1 : "transparent",
                    border: isOutlined ? `2px solid ${color.orange.orange1}` : "none",
                },
            }),
            ...(colors === "quaternary" && {
                backgroundColor: !isOutlined
                    ? color.green.baseGreen100
                    : "transparent",
                color: isOutlined
                    ? color.green.baseGreen100
                    : color.primary.contrastText,
                border: isOutlined ? `2px solid ${color.green.baseGreen100}` : "none",
                "&:hover": {
                    backgroundColor: !isOutlined ? color.green.green1 : "transparent",
                    border: isOutlined ? `2px solid ${color.green.green1}` : "none",
                },
            }),
            ...(colors === 'sixth' && {
                backgroundColor: !isOutlined ? '#170F49' : 'transparent',
                color: isOutlined ? '#170F49' : '#fff',
                border: isOutlined ? `2px solid #170F49` : 'none',
                '&:hover': {
                    backgroundColor: !isOutlined ? '#170F49' : 'transparent',
                    color: isOutlined ? '#170F49' : '#fff',
                    border: isOutlined ? `2px solid #170F49` : 'none',
                },
                '&.Mui-disabled': {
                    backgroundColor: theme.palette.action.disabledBackground,
                    color: theme.palette.action.disabled,
                },
            }),
        };
    }
);

CustomButton.propTypes = {
    colors: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary", "sixth"]),
    rounded: PropTypes.bool,
    variant: PropTypes.oneOf(["contained", "outlined"]),
    size: PropTypes.oneOf(["small", "large"]),
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
};

CustomButton.defaultProps = {
    colors: "primary",
    rounded: false,
    variant: "contained",
    size: "small",
    startIcon: null,
    endIcon: null,
};


export {CustomButton};
