import axiosInstance from "../authentication/axiosInstance";
import {getCookie} from "../cookies/getCookie";

const fetchMetrics = async (propertyId, timePeriod) => {
    const token = localStorage.getItem('jwtToken');
    const csrfToken = getCookie('csrftoken');

    if (!token) {
        console.error("No JWT token found in localStorage.");
        return null;
    }

    const calculateDates = (timePeriod) => {
        let startDate, endDate;
        const today = new Date();

        switch (timePeriod) {
            case 'today':
                startDate = endDate = formatDate(today);
                break;
            case 'yesterday':
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                startDate = endDate = formatDate(yesterday);
                break;
            case 'last_week':
                const lastWeek = new Date(today);
                lastWeek.setDate(lastWeek.getDate() - 7);
                startDate = formatDate(lastWeek);
                endDate = formatDate(today);
                break;
            case 'last_month':
                const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                startDate = formatDate(startOfLastMonth);
                endDate = formatDate(endOfLastMonth);
                break;
            case 'last_3_months':
                const startOfLast3Months = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
                startDate = formatDate(startOfLast3Months);
                endDate = formatDate(today);
                break;
            case 'last_6_months':
                const startOfLast6Months = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
                startDate = formatDate(startOfLast6Months);
                endDate = formatDate(today);
                break;
            case 'last_year':
                const startOfLastYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
                startDate = formatDate(startOfLastYear);
                endDate = formatDate(today);
                break;
            case 'all_time':
                startDate = formatDate(new Date(2016, 1, 1)); // Using formatDate to ensure consistency
                endDate = formatDate(today);
                break;
            default:
                throw new Error(`Unsupported time period: ${timePeriod}`);
        }

        return {startDate, endDate};
    };

    const formatDate = (date) => {
        // Format date as 'YYYY-MM-DD'
        return date.toISOString().split('T')[0];
    };

    // Calculate dates based on the timePeriod
    const {startDate, endDate} = calculateDates(timePeriod);

    try {
        const response = await axiosInstance.post(`/getmetrics/${propertyId}/`, {
            start_date: startDate,
            end_date: endDate
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken
            },
            withCredentials: true
        });
        return response.data;
    } catch (err) {
        console.error("Error fetching metrics:", err);
    }
};

export default fetchMetrics;