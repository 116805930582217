import React from "react";
import {FormControl} from "@mui/material";
import {CustomSelect} from "../customComponents/CustomSelect";
import {CustomMenuItem} from "../customComponents/CustomMenuItem";


const Dropdown = ({properties, selectedPropertyId, setSelectedPropertyId, disabled}) => {
    let propertiesArray = [];

    if (properties) {
        propertiesArray = Object.values(properties).flatMap(account => {
            // Ensure each account has a properties object before attempting to map over it
            return account.properties ? Object.values(account.properties).map(property => ({
                propertyId: property.property_number,
                displayName: `${property.display_name} (${property.property_number})`,
            })) : [];
        });
    }

    const handlePropertyChange = (event) => {
        const propertyId = event.target.value;
        setSelectedPropertyId(propertyId);
    };

    return (
        <FormControl variant="outlined" fullWidth size="small" disabled={disabled}>
            <CustomSelect
                labelId="property-select-label"
                id="property-select"
                value={selectedPropertyId || ""}
                onChange={handlePropertyChange}
                displayEmpty
                aria-labelledby="property-select-label"
            >
                <CustomMenuItem value="" disabled>
                    Velg en av dine nettsider
                </CustomMenuItem>
                {propertiesArray.map((property) => (
                    <CustomMenuItem key={property.propertyId} value={property.propertyId}>
                        {property.displayName}
                    </CustomMenuItem>
                ))}
            </CustomSelect>
        </FormControl>
    );
};

export default Dropdown;
