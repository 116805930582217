import {useEffect, useState} from 'react';
import fetchMetrics from './fetchMetrics';

const useMetrics = (initialPropertyId, initialTimePeriod) => {
    const [selectedData, setSelectedData] = useState(null);
    const [metricsLoading, setMetricsLoading] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState(initialPropertyId);
    const [timePeriod, setTimePeriod] = useState(initialTimePeriod);

    useEffect(() => {
        // Fetch metrics whenever the selectedPropertyId or timePeriod changes
        if (selectedPropertyId && timePeriod) {
            setMetricsLoading(true);
            fetchMetrics(selectedPropertyId, timePeriod)
                .then(data => {
                    setSelectedData(data);
                })
                .catch(error => {
                    console.error("Error fetching metrics:", error);
                })
                .finally(() => {
                    setMetricsLoading(false);
                });
        }
    }, [selectedPropertyId, timePeriod]);

    const handleMetricsFetched = (metrics) => {
        setMetricsLoading(false);
        setSelectedData(metrics);
    };

    const updateTimePeriod = async (newTimePeriod) => {
        // Update the time period state immediately to reflect the user's choice.
        setTimePeriod(newTimePeriod);

        if (selectedPropertyId) {
            setMetricsLoading(true);
            try {
                const metrics = await fetchMetrics(selectedPropertyId, newTimePeriod);
                if (metrics) {
                    handleMetricsFetched(metrics);
                } else {
                    console.error("Failed to fetch metrics: No data returned");
                }
            } catch (error) {
                console.error("Error updating metrics for new time period:", error);
            } finally {
                setMetricsLoading(false);
            }
        } else {
            console.error("No property ID selected");
        }
    };


    return {
        selectedData,
        metricsLoading,
        setMetricsLoading,
        selectedPropertyId,
        timePeriod,
        setSelectedPropertyId,
        setTimePeriod,
        updateTimePeriod,
    };
};

export default useMetrics;
