import * as React from 'react';
import {useEffect, useLayoutEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {Alert, CircularProgress, Container, CssBaseline, useMediaQuery} from "@mui/material";
import '../assets/css/divider.css'
import GoogleAuthButton from "../components/login/googleLogin";
import {useNavigate} from "react-router-dom";
import handleLogin from "../components/login/handleLogin";
import {useAuth} from "../helperFunctions/authentication/authContext";
import axiosInstance from "../helperFunctions/authentication/axiosInstance";
import '../assets/css/SignInSide.css';
import logoFinal from '../assets/img/logo-final.webp';
import "../colors.css"
import {CustomTextField} from '../components/customComponents/CustomTextField';
import {color} from '../theme';
import {CustomButton} from "../components/customComponents/CustomButton";
import videoSrcSmall from "../assets/mp4/slideshow-small.mp4";
import videoSrc from "../assets/mp4/slideshow.mp4";
import Grid from "@mui/material/Grid";


const SignInSide = () => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false); // Add loading state
    const navigate = useNavigate();
    const {login} = useAuth();
    const [emailInput, setEmailInput] = useState('');
    const [emailInfo, setEmailInfo] = useState(null);

    // Responsive design for smaller screens
    const theme = useTheme();
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useLayoutEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const checkEmailFormat = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        if (checkEmailFormat(emailInput)) {
            axiosInstance.post('/check-email/', {
                email: emailInput
            })
                .then(response => {
                    setEmailInfo(response.data);
                })
                .catch(error => console.error('Error checking email:', error));
        }
    }, [emailInput]);


    const handleSubmit = async (event) => {
        // Non-google login flow handler
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');

        const params = {
            email: email,
            password: password
        };

        setIsLoading(true);
        await handleLogin(params, navigate, login, setErrorMessage);
        setIsLoading(false);
    };

    // Determine if the login button should be disabled
    const loginDisabled = emailInfo && emailInfo.email_exists && emailInfo.created_via_google;

    const video = isSmallScreen ? videoSrcSmall : videoSrc;

    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            overflowY: 'auto',
            overflowX: 'auto'
        }}>
            <CssBaseline/>
            <Container component="main" maxWidth="false" disableGutters
                       sx={{height: '100vh', padding: 0}}>
                <Box sx={{
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                    bgcolor: color.beige.beige100,
                    width: "100%",
                    boxShadow: `${color.black.black30} 0px 4px 8px`,
                    height: "100%",
                    overflow: 'hidden',
                }}>
                    {!isSmallScreen && (
                        <Box sx={{
                            width: '100%',
                            height: "auto",
                            overflow: 'hidden' // Ensure the video fits nicely within the box
                        }}>
                            <video autoPlay loop muted style={{width: '100%', height: '100%', objectFit: 'cover'}}>
                                <source src={video} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                    )}

                    <Box
                        sx={{
                            width: '100%',
                            margin: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            mb: 2,
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: isSmallScreen ? '0' : '100%',
                                height: isSmallScreen ? '0' : 'auto',
                                mb: isSmallScreen ? '0' : '4em',
                                visibility: isSmallScreen ? 'hidden' : 'visible',
                                mt: '2em',
                                ml: isSmallScreen ? '0' : '4em',
                            }}>
                                <a href="https://groweb.no" target="_blank" rel="noopener noreferrer"
                                   aria-label="Gå til Growebs hjemmeside">
                                    <img src={logoFinal} alt="Groweb logo2" style={{height: "80px"}}/>
                                </a>
                            </Box>
                            <Typography variant="h3" sx={{fontFamily: 'Fraunces'}}>
                                Velkommen tilbake!
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1, margin: '0 0 2em 0'}}>
                            <Typography variant="body" sx={{
                                fontFamily: 'Fraunces',
                                textAlign: 'center',
                                color: 'rgba(0, 0, 0, 0.6)',
                                fontWeight: 'bold',
                            }}>Har du ikke registrert deg enda? <a href="/register" style={{
                                textDecoration: 'none',
                                fontWeight: 'bold',
                                fontFamily: 'Fraunces',
                                color: color.lightBlue.baseBlue100,
                            }}>Registrer
                                konto</a></Typography>
                        </Box>
                        <GoogleAuthButton isLoading={isLoading}
                                          setIsLoading={setIsLoading}/>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 4, margin: '3em 0 1em 0'}}>
                            <div style={{
                                flex: 1,
                                height: '1px',
                                width: '12vw', // Set to longer on smaller screens
                                backgroundColor: '#605BFFCC'
                            }}></div>
                            <Typography variant="body"
                                        sx={{color: color.green.baseGreen100, fontWeight: '600'}}> eller </Typography>
                            <div style={{
                                flex: 1,
                                height: '1px',
                                width: '12vw',
                                backgroundColor: '#605BFFCC'
                            }}></div>
                        </Box>

                        <Box component="form" onSubmit={handleSubmit} noValidate
                             sx={{mt: 1, display: 'flex', flexDirection: 'column'}}>
                            <Grid container spacing={1} justifyContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box width="100%" maxWidth="440px" sx={{margin: '0'}}>
                                            <CustomTextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                placeholder='dinmail@gmail.no'
                                                name="email"
                                                autoComplete="email"
                                                label="E-post"
                                                autoFocus
                                                value={emailInput}
                                                onChange={(e) => {
                                                    setEmailInput(e.target.value);
                                                    setErrorMessage(''); // Clear error message when email input changes
                                                }}
                                                sx={{bgcolor: '#fff'}}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        <Box width="100%" maxWidth="440px">
                                            <CustomTextField
                                                margin="normal"
                                                placeholder='Sett inn ditt passord'
                                                required
                                                fullWidth
                                                name="password"
                                                type="password"
                                                id="password"
                                                autoComplete="current-password"
                                                label="Passord"
                                                onChange={() => setErrorMessage('')}
                                                sx={{bgcolor: '#fff'}}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            {errorMessage && (
                                <Alert severity="error" sx={{mb: 2}}>
                                    {errorMessage}
                                </Alert>
                            )}

                            {emailInfo && emailInfo.email_exists && emailInfo.created_via_google && emailInput && (
                                <Alert severity="info" sx={{mt: 2, mb: 2, borderRadius: '4px'}}>
                                    E-posten {emailInput} er knyttet til en Google-registrert konto.<br/>
                                    Bruk "Logg inn med Google" for å logge inn med denne e-posten.
                                </Alert>
                            )}

                            <Box>
                                <CustomButton
                                    colors="sixth"
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        mt: '2em',
                                        mb: '3em',
                                        width: isSmallScreen ? "70%" : "55%",
                                        padding: '0.5em',
                                        textTransform: 'capitalize',
                                    }}
                                    disabled={emailInfo && emailInfo.email_exists && emailInfo.created_via_google && emailInput}
                                >
                                    {isLoading ? <CircularProgress size={30} style={{color: '#FFFFFF'}}/> : 'Logg inn'}
                                </CustomButton>
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography variant="caption"
                                                sx={{
                                                    color: 'rgba(0, 0, 0, 0.6)',
                                                    fontWeight: 'bold',
                                                    fontFamily: 'Fraunces'
                                                }}>Glemt
                                        passordet? <a
                                            href="/reset" style={{
                                            textDecoration: 'none',
                                            fontWeight: 'bold',
                                            fontFamily: 'Fraunces',
                                            color: color.lightBlue.baseBlue100,
                                        }}>Tilbakestill passord</a></Typography>
                                </Box>
                            </Box>
                            <Box sx={{bottom: '0', position: 'absolute', marginBottom: '1em'}}>
                                <Typography variant="caption" sx={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                    Denne siden er beskyttet med reCAPTCHA og Google <a
                                    href="https://policies.google.com/privacy" target="_blank"
                                    rel="noopener noreferrer"
                                    style={{textDecoration: 'none', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.6)'}}>
                                    Personvern</a> og <a
                                    href="https://policies.google.com/terms" target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                        fontWeight: 'bold',
                                        color: 'rgba(0, 0, 0, 0.6)',
                                    }}>Bruksvilkår</a> gjelder.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default SignInSide;