import React, {useState} from 'react';
import {Box, Card, CardContent, Container, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Navbar from '../../components/navbar/Navbar';
import {color} from '../../theme';
import PaywallModal from "../../components/stripe/PaywallModal";
import {CustomButton} from "../../components/customComponents/CustomButton";
import {useNavigate} from "react-router-dom";

const PaymentFailedPage = () => {
    const [openPaywallModal, setOpenPaywallModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const [modalTrial, setModalTrial] = useState(false);
    const navigate = useNavigate();

    const handleOpenPaywallModal = (text, trial) => {
        setModalText(text);
        setModalTrial(trial);
        setOpenPaywallModal(true);
    };

    const handleClosePaywallModal = () => {
        setOpenPaywallModal(false);
    };

    return (
        <Container sx={{display: 'flex', flexDirection: 'row', height: '100vh'}} maxWidth="lg">
            <Navbar/>
            <Box sx={{
                marginLeft: '208px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'
            }}>
                <Card sx={{minWidth: 275, padding: '2em'}}>
                    <CardContent sx={{textAlign: 'center'}}>
                        <ErrorIcon sx={{fontSize: 80, color: color.orange.orange1, marginBottom: '0.2em'}}/>
                        <Typography variant="h4" component="div" gutterBottom>
                            Betaling mislyktes
                        </Typography>
                        <Typography variant="body" sx={{marginTop: '0.5em'}}>
                            Vi kunne dessverre ikke behandle betalingen din.
                        </Typography>
                        <Typography variant="body" display="block" sx={{marginTop: '0.5em'}}>
                            Vennligst prøv igjen.
                        </Typography>
                        <Box sx={{marginTop: '1em'}}>
                            <CustomButton
                                variant="contained"
                                colors="quaternary"
                                onClick={() =>
                                    handleOpenPaywallModal(
                                        'Start din Groweb-abonnement',
                                        !localStorage.getItem('hasHadTrial')
                                    )
                                }
                            >
                                Prøv på nytt
                            </CustomButton>
                        </Box>
                        <Box sx={{marginTop: '2em'}}>
                            <CustomButton variant="outlined" onClick={() => navigate("/nettside")}>Gå tilbake til
                                Nettside</CustomButton>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <PaywallModal
                openPaywallModal={openPaywallModal}
                handleClosePaywallModal={handleClosePaywallModal}
                text={modalText}
                trial={modalTrial}
            />
        </Container>
    );
};

export default PaymentFailedPage;
