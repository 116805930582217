import {Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import {CustomCard} from "../customComponents/CustomCard";

const SkeletonStatsCard = () => {
    return (
        <CustomCard size="xs" sx={{display: "flex", alignItems: "center", position: "relative"}}>
            <Box display="flex" width="100%">
                <Box display="flex" alignItems="center" justifyContent="center" gap={2} width="100%">
                    {/* Skeleton for the Icon */}
                    <div className="stats-icon">
                        <Skeleton variant="circular" width={40} height={40}/>
                    </div>

                    {/* Skeleton for the text content */}
                    <Box>
                        <Typography variant="h5">
                            <Skeleton width={80}/>
                        </Typography>

                        <Box display="flex" alignItems="center">
                            <Skeleton width={130}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CustomCard>
    );
};

export default SkeletonStatsCard;
