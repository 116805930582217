import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {CustomButton} from "../customComponents/CustomButton";
import CustomTooltip from "../customComponents/CustomTooltip";
import deleteProject from '../../helperFunctions/nettside/deleteProject';
import addCustomDomain from '../../helperFunctions/nettside/addCustomDomain';
import fetchSavedDomain from '../../helperFunctions/nettside/fetchSavedDomain';
import detachCustomDomain from '../../helperFunctions/nettside/detachCustomDomain';
import getDnsDetails from '../../helperFunctions/nettside/getDnsDetails';
import changeSubdomain from '../../helperFunctions/nettside/changeSubdomain';
import modalStyle from '../customComponents/modalStyle';
import fetchSubdomain from "../../helperFunctions/nettside/fetchSubdomain";
import {CustomTextField} from "../customComponents/CustomTextField";


const isValidDomain = (domain) => {
    // Regular expression to match valid domain names without subdomains or paths
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/)?$/;
    return domainPattern.test(domain);
};


const SsoLinkDisplay = ({ssoLink, siteNumber, projectId, onDeleteSuccess}) => {
    const [openModal, setOpenModal] = useState(false);
    const [openDetachModal, setOpenDetachModal] = useState(false);
    const [subdomainName, setSubdomainName] = useState('');
    const [subdomain, setSubdomain] = useState('');
    const [customDomainName, setCustomDomainName] = useState('');
    const [dnsDetails, setDnsDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    const [isDomainValid, setIsDomainValid] = useState(true);

    useEffect(() => {
        if (isAccordionExpanded) {
            getSavedDomain();
            getDomainsInfo();
        }
    }, [isAccordionExpanded]);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenDetachModal = () => setOpenDetachModal(true);
    const handleCloseDetachModal = () => setOpenDetachModal(false);

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await deleteProject(projectId);
            if (response.message) {
                console.warn(response.message);
            }
            onDeleteSuccess(projectId);
            setError('');
            handleCloseModal();
            window.location.reload();
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSubdomainSave = async () => {
        setLoading(true);
        try {
            await changeSubdomain(projectId, subdomain, subdomainName);
            setSubdomain(subdomainName);  // Directly use the new subdomain
            setError('');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                setError('Subdomene navnet er ugyldig eller allerede tatt. Vennligst prøv igjen med et annet navn.');
            } else {
                setError('En feil oppstod. Vennligst prøv igjen senere.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCustomDomainSave = async () => {
        setLoading(true);
        try {
            const response = await addCustomDomain(projectId, customDomainName);
            const dnsResponse = await getDnsDetails(projectId, customDomainName); // Fetch DNS details after adding custom domain
            setDnsDetails(dnsResponse.dns_details);
            setError('');
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getSavedDomain = async () => {
        setLoading(true);
        try {
            const response = await fetchSavedDomain(projectId);
            if (response.custom_domain) {
                setCustomDomainName(response.custom_domain.domain_name);
                const dnsResponse = await getDnsDetails(projectId, response.custom_domain.domain_name);
                setDnsDetails(dnsResponse.dns_details);
                setError('');
            } else {
                setCustomDomainName('');
                setDnsDetails(null);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getDomainsInfo = async () => {
        try {
            const response = await fetchSubdomain(projectId);
            setSubdomainName(response.subdomain_name);
            setSubdomain(response.subdomain);
            setError('');
        } catch (error) {
            setError(error.message);
        }
    };

    const refreshDnsDetails = async () => {
        setLoading(true);
        try {
            const response = await getDnsDetails(projectId, customDomainName);
            setDnsDetails(response.dns_details);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDetachDomain = async () => {
        setLoading(true);
        try {
            const response = await detachCustomDomain(projectId, customDomainName);
            setDnsDetails(null);
            setCustomDomainName('');
            setError('');
            handleCloseDetachModal();
            if (response.data && response.data.message) {
                console.log(response.data.message);  // For debug purposes
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    const handleAccordionToggle = () => {
        setIsAccordionExpanded(!isAccordionExpanded);
    };

    const handleCustomDomainChange = (e) => {
        const domain = e.target.value;
        setCustomDomainName(domain);
        setIsDomainValid(isValidDomain(domain));
    };

    return (
        <Card variant="outlined" sx={{width: '100%', mb: '2em'}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2}}>
                <Typography variant='h5'>{siteNumber}</Typography>
                <Box component="div" sx={{textAlign: 'center', display: 'flex', gap: 2}}>
                    <CustomTooltip
                        title="Har du problemer med linken? Opprett en ny link til din nettside ved å oppdatere siden (F5-knappen)."
                        placement="right"
                    >
                        <CustomButton onClick={() => window.open(ssoLink, '_blank')}
                                      type="primary" colors="primary"
                        >
                            Rediger Nettside
                        </CustomButton>
                    </CustomTooltip>
                    <CustomTooltip
                        title="Publiser din nettside til din egen ekstern domene eller bruk en av Growebs subdomener."
                        placement="right"
                    >
                        <CustomButton onClick={handleAccordionToggle} colors="quaternary" sx={{ml: 1}}>
                            Publiser
                        </CustomButton>
                    </CustomTooltip>
                    <CustomButton onClick={handleOpenModal} colors="tertiary" sx={{ml: 1}}>
                        Slett nettside
                    </CustomButton>
                </Box>
            </Box>
            {isAccordionExpanded && (
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls={`panel${siteNumber}a-content`}
                        id={`panel${siteNumber}a-header`}
                        sx={{justifyContent: 'space-between', alignContent: 'center'}}
                    >
                    </AccordionSummary>
                    <AccordionDetails>
                        <CardContent>
                            {loading ? (
                                <CircularProgress/>
                            ) : dnsDetails ? (
                                <Box sx={{mt: 2}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Typography variant="h5" gutterBottom>Custom domene</Typography>
                                        <CustomTooltip
                                            title="Oppdater DNS-data"
                                            arrow>
                                            <IconButton onClick={refreshDnsDetails} disabled={loading}>
                                                <RefreshIcon/>
                                            </IconButton>
                                        </CustomTooltip>
                                    </Box>
                                    <Typography variant="h5" sx={{mb: 1}} gutterBottom>Koblet domene</Typography>
                                    <Typography variant="body" sx={{mb: 2}} gutterBottom>{customDomainName}</Typography>
                                    <Typography variant="h5" sx={{mt: 2, mb: 2}} gutterBottom>DNS
                                        Innstillinger</Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Host</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Host Data</TableCell>
                                                    <TableCell>Nåværende Data</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dnsDetails.cname.map((record, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{record.required_data.host}</TableCell>
                                                        <TableCell>CNAME</TableCell>
                                                        <TableCell>{record.required_data.target}</TableCell>
                                                        <TableCell
                                                            sx={{color: record.required_data.target !== record.current_data.target ? 'red' : 'green'}}>
                                                            {record.current_data.target}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {dnsDetails.a.map((record, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{record.required_data.host}</TableCell>
                                                        <TableCell>A</TableCell>
                                                        <TableCell>{record.required_data.target}</TableCell>
                                                        <TableCell
                                                            sx={{color: record.required_data.target !== record.current_data.target ? 'red' : 'green'}}>
                                                            {record.current_data.target}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <CustomButton colors="tertiary" sx={{mt: 4}}
                                                  onClick={handleOpenDetachModal}>
                                        Koble av domene
                                    </CustomButton>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', gap: 4}}>
                                    <Box sx={{flex: 1}}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}>
                                            <Typography variant="h5" gutterBottom sx={{textAlign: 'center', flex: 1}}>
                                                Bytt subdomenenavn
                                            </Typography>
                                        </Box>
                                        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                            <CustomTextField
                                                variant="outlined"
                                                fullWidth
                                                value={subdomainName}
                                                onChange={(e) => setSubdomainName(e.target.value)}
                                                placeholder={siteNumber}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="body">.groweb.cloud</Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <CustomButton
                                                onClick={() => window.open(`http://${subdomainName}.groweb.cloud`, '_blank')}
                                                disabled={!subdomainName}
                                                endIcon={<OpenInNewIcon/>}
                                                sx={{alignSelf: 'end'}}
                                                colors="primary"
                                                size="small"
                                                variant="outlined"
                                            >
                                                Åpne
                                            </CustomButton>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}>
                                                <CustomButton
                                                    colors="primary"
                                                    onClick={handleSubdomainSave}
                                                    disabled={loading}
                                                    sx={{marginRight: 'auto'}}
                                                >
                                                    Lagre
                                                </CustomButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider orientation="vertical" flexItem/>
                                    <Box sx={{flex: 1}}>
                                        <Typography variant="h5" gutterBottom>Legg til eget domene</Typography>
                                        <CustomTextField
                                            variant="outlined"
                                            fullWidth
                                            value={customDomainName}
                                            onChange={handleCustomDomainChange}
                                            placeholder={`Her skriver du adressen til ditt eget domene (for eksempel bedriftnavn.no)`}
                                            error={!isDomainValid}
                                            helperText={!isDomainValid && "Ugyldig domene"}
                                        />
                                        <CustomButton colors="primary" onClick={handleCustomDomainSave}
                                                      disabled={loading || !isDomainValid} sx={{mt: '2.2em'}}>
                                            Fortsett
                                        </CustomButton>
                                    </Box>
                                </Box>
                            )}
                            {error && <Typography color="error">{error}</Typography>}
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            )}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...modalStyle, width: 500}}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Er du sikker på at du vil slette {siteNumber}?
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>}
                    <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-between'}}>
                        <CustomButton colors="primary" onClick={handleCloseModal} disabled={loading}>
                            Avbryt
                        </CustomButton>
                        <Button variant="contained" color="error" onClick={handleDelete} disabled={loading}>
                            Slett (Kan ikke angres)
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openDetachModal}
                onClose={handleCloseDetachModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...modalStyle, width: 400}}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        Er du sikker på at du vil koble av domenet {customDomainName}?
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>}
                    <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-between'}}>
                        <CustomButton colors="primary" onClick={handleCloseDetachModal} disabled={loading}>
                            Avbryt
                        </CustomButton>
                        <Button variant="contained" color="error" onClick={handleDetachDomain} disabled={loading}>
                            Fjern
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Card>
    );
};

export default SsoLinkDisplay;
