import Typography from "@mui/material/Typography";
import React from "react";
import {CustomCard} from "../../customComponents/CustomCard";
import {Box} from "@mui/material";
import {CustomButton} from "../../customComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import '../../../assets/css/dashboard/LagNettside.css'

const LagNettside = () => {
    const navigate = useNavigate();
    const malerNettsideVideoUrl = "https://growebmp4container-euczdjadazhtdkhf.z02.azurefd.net/growebmp4container/maler-nettside.webm"
    const goToNettside = () => {
        navigate('/nettside');
    }
    return (
        <div className="lag-nettside-container">
            <CustomCard size="m" sx={{padding: '32px'}} id="lagNettsideCard">
                <Typography variant="h5" id="title">Lag en nettside
                </Typography>
                <Box
                    component="video"
                    sx={{width: '100%', height: '305px'}}
                    src={malerNettsideVideoUrl}
                    preload="metadata"
                    autoPlay
                    loop
                    muted
                />
                <CustomButton colors="quaternary" variant="contained"
                              size="large"
                              sx={{width: '90%'}}
                              onClick={goToNettside}>
                    Velg nettsidetype
                </CustomButton>
            </CustomCard>
        </div>
    )
}

export default LagNettside