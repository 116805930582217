import {styled} from '@mui/system';
import {Select} from '@mui/material';
import {color} from '../../theme';

const CustomSelect = styled((props) => <Select {...props} />)({
    borderColor: color.lightGrey.lightGrey100S1,
    '&:hover': {
        '&& fieldset': {
            borderColor: color.lightGrey.lightGrey100S2,
        },
    },
    '&.Mui-focused': {
        borderColor: color.lightBlue.baseBlue100,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: color.lightGrey.lightGrey100S1,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color.lightBlue.baseBlue100,
    },
    '& .MuiSelect-select': {
        fontFamily: 'Poppins',
    },
});

export {CustomSelect};
