import React, {useEffect, useState} from 'react';
import {Modal, Box, Typography, LinearProgress} from '@mui/material';
import modalStyle from '../customComponents/modalStyle';
import loading from '../../assets/mp4/loading.mp4'
import {color} from "../../theme";

const AiLoadingModal = ({open, duration = 10000, handleClose}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (open) {
            let startTime = Date.now();
            const timer = setInterval(() => {
                const elapsed = Date.now() - startTime;
                const percentage = Math.min((elapsed / duration) * 100, 100);
                setProgress(percentage);
                if (percentage >= 100) {
                    clearInterval(timer);
                    if (handleClose) handleClose();
                }
            }, 100);

            return () => clearInterval(timer);
        } else {
            setProgress(0);
        }
    }, [open, duration, handleClose]);

    return (
        <Modal
            open={open}
            onClose={(event, reason) => {
                // Prevent closing the modal by clicking outside or pressing escape
                if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                    event.preventDefault();
                }
            }}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
        >
            <Box
                sx={{
                    ...modalStyle,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '400px',
                    width: '400px',
                }}
            >
                <Typography variant="h5" sx={{mb: 2}}>
                    Din nettside bygges...
                </Typography>
                <video
                    src={loading}
                    autoPlay
                    loop
                    muted
                    style={{width: '250px', height: '250px', marginBottom: '20px'}}
                />
                {/* Progress Bar */}
                <Box sx={{width: '100%', mt: 1, color: color.green.baseGreen100}}>
                    <LinearProgress variant="determinate" value={progress} color='inherit'/>
                </Box>
            </Box>
        </Modal>
    );
};

export default AiLoadingModal;
