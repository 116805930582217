import React from "react";
import image from "../../assets/img/trafikkUtenProperties.webp";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'; // Import the notification icon
import Typography from "@mui/material/Typography";

export const TrafficChartNoProperties = () => {
    return (
        <div className="chartContainer" id="chartContainerNoProperties">
            <div className="chartHeader">
                <Typography variant="h5">Trafikk</Typography>
            </div>
            <div className="chart" id="chartNoProperties">
                <div className="notification-banner">
                    <NotificationImportantIcon className="notification-icon"/>
                    <span style={{marginTop: '5px'}}>Velg en nettside for å se trafikkdata</span>
                </div>
                <img src={image} alt="Error" style={{filter: 'blur(5px)', width: '90%', height: '100%'}}/>
            </div>
        </div>
    );
}
