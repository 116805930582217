// handleAiFormSubmit.js
import axiosInstance from '../../helperFunctions/authentication/axiosInstance';
import createAiSSOLink from './createAiSsoLink';

const handleAiFormSubmit = async (
    e,
    aiFormData,
    setSsoLink,
    setIsSubmitting,
    setErrorMessage,
    setOpenLinkModal,
    handleCloseAiBuilderModal,
    setOpenLoadingModal,
    setLoadingDuration
) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoadingDuration(8000); // Set duration to 8 seconds
    setOpenLoadingModal(true);

    try {
        // Close the AI builder modal
        handleCloseAiBuilderModal();

        // Create an AI-generated project
        const aiProjectResponse = await axiosInstance.post('/create-ai-project/', {
            company: aiFormData.businessName,
            city: aiFormData.city,
            prompt: aiFormData.industry,
            lang: aiFormData.language,
            phone: aiFormData.phone,
            email: aiFormData.email,
            description: aiFormData.description,
        });
        const aiProjectId = aiProjectResponse.data.id;

        // Generate the SSO link
        const ssoResponse = await createAiSSOLink(aiProjectId);
        setSsoLink(ssoResponse.sso_link);
        setErrorMessage('');

    } catch (error) {
        setErrorMessage('Det oppstod en feil under opprettelsen av AI-prosjektet eller SSO-lenken.');
        setOpenLoadingModal(false);
    } finally {
        setIsSubmitting(false);
    }
};

export default handleAiFormSubmit;
