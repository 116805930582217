import {styled} from '@mui/system';
import {Checkbox} from '@mui/material';
import {color} from "../../theme";

const CustomCheckbox = styled((props) => <Checkbox {...props} inputProps={{'aria-label': props['aria-label']}}/>)({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
        borderWidth: '1px',
        borderColor: color.black.black30,
        borderRadius: '4px',
    },
    '&.Mui-checked .MuiSvgIcon-root': {
        color: color.lightBlue.baseBlue100,
    },
    padding: 0,
    pr: 0.5,
    '&:hover': {
        bgcolor: 'transparent',
    },
});

export {CustomCheckbox};