import React from 'react';
import Dropdown from "../../propertiesDropdown/DropdownProperties";
import CustomTooltip from "../../customComponents/CustomTooltip";

const PropertySelectionDropdown = ({properties, setSelectedPropertyId, selectedPropertyId, timePeriod, disabled}) => {
    return (
        <CustomTooltip
            title="Dette er den valgte nettsiden din fra Google Analytics det vises datainnsikt av. Valget av nettsted kan endres i innstillinger."
            placement="top">
            <div> {/* Tooltip needs a single child, hence wrapping Dropdown in a div */}
                <Dropdown
                    properties={properties}
                    setSelectedPropertyId={setSelectedPropertyId}
                    selectedPropertyId={selectedPropertyId}
                    timePeriod={timePeriod}
                    disabled={disabled}
                    aria-label="Velg nettside fra Google Analytics."
                />
            </div>
        </CustomTooltip>
    );
};

export default PropertySelectionDropdown;