import React from 'react';
import PropertySelectionDropdown from "./PropertySelectionDropdown";
import DateSelectionDropdown from "./DateSelectionDropdown";
import '../../../assets/css/dropdowns/dashboard-dropdowns.css';
import {CustomInputLabel} from "../../customComponents/CustomInputLabel"

const DashboardDropdowns = ({
                                metricsData,
                                updateTimePeriod,
                                properties,
                                setSelectedPropertyId,
                                selectedPropertyId,
                                timePeriod,
                                disablePropertySelection = true// disabled by default
                            }) => {
    // Determine if the DateSelectionDropdown should be disabled
    const isDateSelectionDisabled = !selectedPropertyId;
    return (
        <>
            <div className="dashboardDropdowns">
                <div className="dropdownSelectionDiv">
                    <CustomInputLabel id="property-select-label" htmlFor="property-select">Nettside</CustomInputLabel>
                    <PropertySelectionDropdown
                        properties={properties}
                        setSelectedPropertyId={setSelectedPropertyId}
                        selectedPropertyId={selectedPropertyId}
                        timePeriod={timePeriod}
                        disabled={disablePropertySelection}
                        aria-label="Velg nettside fra Google Analytics."
                    />
                </div>
                <div className="dropdownSelectionDiv">
                    <CustomInputLabel id="date-select-label" htmlFor='date'>Dato</CustomInputLabel>
                    <DateSelectionDropdown
                        timePeriod={timePeriod}
                        updateTimePeriod={updateTimePeriod}
                        disabled={isDateSelectionDisabled}
                        aria-label="Velg en dato."
                    />
                </div>
            </div>
        </>
    );
};

export default DashboardDropdowns;