// GoogleConnectButton.js
import React from "react";
import {HubOutlined} from "@mui/icons-material";
import {useGoogleAuth} from "../../helperFunctions/google/useGoogleAuth";
import {CustomButton} from "../customComponents/CustomButton";

const GoogleConnectButton = () => {
    const {initiateGoogleConnect, error} = useGoogleAuth()

    return (
        <CustomButton
            colors='primary'
            variant='outlined'
            endIcon={<HubOutlined/>}
            onClick={initiateGoogleConnect}
        >
            Koble til Google-konto
        </CustomButton>
    );
};

export default GoogleConnectButton;
