import axiosInstance from "../authentication/axiosInstance";

// handleConnectGoogle.js
const handleConnectGoogle = async (code, onConnectSuccess, onConnectError) => {
    try {
        const response = await axiosInstance.post('/connect-google-account/', {
            code: code
        });

        if (response.status === 200) {
            const jwtToken = response.data.token;
            const google_access_token = response.data.google_access_token;
            const google_refresh_token = response.data.google_refresh_token;
            const connectedGoogleAccount = true; // Since we successfully connected the account
            const google_email = response.data.google_email;

            // Call the success callback
            onConnectSuccess(connectedGoogleAccount, jwtToken, google_access_token, google_refresh_token, google_email);
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error("Failed to connect Google account:", error.response);
            onConnectError("Failed to connect Google account.");
        } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received:", error.request);
            onConnectError("No response received from server.");
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error in request:", error.message);
            onConnectError("Error in setting up the request.");
        }
    }
};

export default handleConnectGoogle;

