// Function to fetch data based on a given time period
import fetchMetrics from "../google/fetchMetrics";
import fetchMetricsTraffic from "../google/fetchMetricsTraffic";

export const fetchDataForTimePeriod = async (selectedPropertyId, timePeriodToFetch, traffic) => {
    try {
        const data = traffic ? await fetchMetricsTraffic(selectedPropertyId, timePeriodToFetch) : await fetchMetrics(selectedPropertyId, timePeriodToFetch); // Fetch data based on the time period
        return data; // Return the fetched data
    } catch (error) {
        console.error("Kunne ikke hente Google data for denne tidsperioden:", timePeriodToFetch, error);
        throw error; // Re-throw the error to be caught by the caller
    }
};