import React from 'react';
import {FormControl} from '@mui/material';
import {CustomSelect} from '../../customComponents/CustomSelect';
import CustomTooltip from "../../customComponents/CustomTooltip";
import {CustomMenuItem} from "../../customComponents/CustomMenuItem";

const DateSelectionDropdown = ({timePeriod, updateTimePeriod, disabled}) => {
    const handleChange = (event) => {
        const newTimePeriod = event.target.value;
        updateTimePeriod(newTimePeriod);
    };

    return (
        <CustomTooltip title="Sett dato for datainnsikt"
                       placement="top">
            <FormControl variant="outlined" fullWidth size="small">
                <CustomSelect
                    labelId="date-select-label"
                    id="date"
                    value={timePeriod}
                    onChange={handleChange}
                    displayEmpty
                    disabled={disabled}
                    aria-labelledby="date-select-label"
                >
                    <CustomMenuItem value="today">I dag</CustomMenuItem>
                    <CustomMenuItem value="last_week">Siste uke</CustomMenuItem>
                    <CustomMenuItem value="last_month">Siste måned</CustomMenuItem>
                    <CustomMenuItem value="last_3_months">Siste 3 måneder</CustomMenuItem>
                    <CustomMenuItem value="last_6_months">Siste 6 måneder</CustomMenuItem>
                    <CustomMenuItem value="last_year">Siste år</CustomMenuItem>
                    <CustomMenuItem value="all_time">All tid</CustomMenuItem>
                </CustomSelect>
            </FormControl>
        </CustomTooltip>
    );
};

export default DateSelectionDropdown;